import React from 'react'
import { useLocation } from 'react-router'

import img1 from "../../../../../assets/service/landing-img-1.png"
import img2 from "../../../../../assets/service/landing-img-2.png"
import img3 from "../../../../../assets/service/landing-img-3.png"

import Container from '../../../../common/container/Container'

import "../../ServiceInfo.scss"

const LandingSite = () => {

    const location = useLocation();

  if (location.pathname === '/auth') {
    return null;
  }
  return (
    <div className='landing-site'>
        <Container>
        <h1>Լենդինգ կայքի պատրաստում</h1>
            <div className='float'>
                <img src={img1} alt="" />
                <img src={img2} alt="" />
                <img src={img3} alt="" />
            </div>
            <p>Լենդինգ կայքը փոքր ու միջին բիզնեսների համար մտածված գործիք է, որն իր մեջ անպայման կրում է 
                գործողության կոչ։ Այն նախատեսված է ձեր ընկերության ծառայությունները կամ ապրանքատեսակը վաճառելու համար։</p>
            <br/>
 
            <p>Նման կայքը, գլխավորապես, կազմված է լինում հետևյալ 3 կետերից՝</p>
            <br/>

            <ul>
                <li>Լավ վերնագիր</li>
                <li>Նշանակետին խփող տեքստ</li>
                <li>Աչքի ընկնող դիզայն</li>
            </ul>

            <br/>

            <p>
            Մեր ծրագրավորողներն, իրենց փորձից ելնելով, ավելացնում են ևս մի քանի կետեր, հիմնված պոտենցիալ հաճախորդների հոգեբանության վրա։
            </p>

            <br />

            <p>Օգտատերն ավելի հեշտ է ընկալում կայքում եղած ուղերձն ու ինֆորմացիան, երբ այն իր մեջ կրում է ոչ ավել, քան մի նպատակ։ Սա վերաբերում է պարզ կայքերին, ինչպիսին է նաև լենդինգ կայքը։ Այն նաև, բացի գործողության կոչ անելու կոճակից (գրանցվել, բաժանորդագրվել, զանգել, գնել), այլ հղումներ չպիտի ունենա, որպեսզի օգտատերը հասնի վերջնակետին առանց շեղվելու։</p>

            <br />        

            <p>Տեքստը նման կայքում պետք է լինի պարզ, հակիրճ և ինֆորմատիվ։ Կարևոր է հստակ պատկերացնել նշված ծառայության, ապրանքատեսակի թարգեթն ու աշխատել հենց նրա համար։ Պետք է միշտ հիշել, որ լենդինգ կայքը պատրաստվում է ոչ թե ծանոթության, այլ օգտատիրոջը կոնկրետ գործողության մղելու համար։</p>
            
            <br />

            <p>Կայքում կարող են տեղ գտնել նաև զեղչեր, տարբեր ակցիաներ․ այն ամենն, ինչը կշարժի պոտենցիալ հաճախորդի հետաքրքրությունը։ Հաճախորդը՝ կայք այցելելով, միշտ համեմատություն է կատարում։ Հետևաբար, ձեր առավելություններն ավելի շատ պիտի լինեն։ Սակայն առավելությունները պետք է նաև վստահություն առաջացնեն։ Այս հարցում կօգնեն ճիշտ ընտրված նկարները, անհրաժեշտության դեպքում կարելի է օգտագործել նաև կարճ տեսանյութ։</p>
            
            <br />

            <p>Լենդինգ կայք պատրաստելու ամենապատասխանատու պահն այս ամբողջ ինֆորմացիան հավաքելուց հետո, այն ճիշտ հերթականությամբ օգտատիրոջը ներկայացնելն է։ Այն է՝ պոտենցիալ հաճախորդի մոտ հետաքրքրություն առաջացնելով՝</p>
            
            <br />

            <p>Ձևավորել տվյալ ծառայության պահանջ
            Բավարարել պահանջն, առաջարկելով լավագույն տարբերակը
            Քայլ առ քայլ մղել նրան կատարել գնում (կամ ցանկացած այլ գործողություն) հենց ՏՎՅԱԼ ՊԱՀԻՆ</p>
        </Container>
    </div>
  )
}

export default LandingSite