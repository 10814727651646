import React from 'react'
import Treaning from '../components/Treaning/Treaning'
import Layout from '../layout/Layout'

const TreaningPage = () => {
  return (
    <div>
        <Layout>
          <Treaning/>
      </Layout>
    </div>
  )
}

export default TreaningPage